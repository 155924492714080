<template>
  <div class="dispatcher-orders-listing">
    <div class="d-flex">
      <div class="col-6">
        <div class="dispatcher-orders-listing-map">
          <google-map
            v-if="
              (carsMarkers && carsMarkers.length) ||
              (ordersMarkers && ordersMarkers.length)
            "
            :cars="carsMarkers"
            :orders="ordersMarkers"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="dispatcher-orders-listing-orders">
          <title-bar addBtn title="Orders" addUrl="/orders/add-order" />
          <b-tabs
            pills
            class="order-listing-tabs"
            v-model="tabIndex"
            @input="courierChange"
          >
            <b-tab
              v-for="(tab, tabIndex) of tabs"
              :key="tabIndex"
              :title="tab.title"
              :title-link-class="{ on: tab.active }"
            >
              <template #title>
                <div v-html="tab.title"></div>
              </template>
            </b-tab>
          </b-tabs>
          <search class="mb-4" @inputChange="inputChange" />
          <div v-if="tabIndex == 2" class="range-container mb-4">
            <flat-pickr
              v-model="orderDates"
              @on-close="loadOrders"
              :config="{
                mode: 'range',
                altFormat: 'd.m.Y H:i',
                dateFormat: 'Z',
                altInput: true,
                altInputClass: 'flatpickr-input',
                time_24hr: true,
                enableTime: true,
              }"
            ></flat-pickr>
          </div>

          <vue-good-table
            class="custom-table-style"
            mode="remote"
            ref="goods_table_ref"
            :totalRows="pages"
            :columns="columns"
            :rows="orders"
            :pagination-options="{
              enabled: true,
              perPage: perPage,
              setCurrentPage: currentPage,
            }"
            :row-style-class="rowStyleClass"
            @on-page-change="onPageChange"
          >
            <template slot="table-row" slot-scope="props">
              <span
                :class="[
                  props.column.field,
                  {
                    draggable:
                      orders.length &&
                      tabIndex !== 0 &&
                      tabIndex !== -1 &&
                      tabIndex !== 1,
                  },
                ]"
                v-if="props.column.field == 'status'"
              >
                <input class="id_holder" type="hidden" :value="props.row.id" />
                <table-bullet :value="props.formattedRow[props.column.field]" />
                <span class="icon confirmed-by-courier">
                  <img
                    v-if="props.row.confirmed"
                    src="@/assets/images/icons/eye.svg"
                    alt=""
                  />
                  <img
                    v-if="!props.row.confirmed"
                    src="@/assets/images/icons/eye-off.svg"
                    alt=""
                  />
                </span>
                <table-bullet value="WAREHOUSE" v-if="props.row.fromWarehouse" />
                <span class="icon from-warehouse" v-if="props.row.fromWarehouse">
                  <img
                      src="@/assets/images/icons/stock.svg"
                      alt="From warehouse"
                  />
                </span>
              </span>
              <span
                :class="props.column.field"
                v-else-if="props.column.field == 'shippingAddress'"
              >
                <div class="order-info">
                  <div class="order-info-flex">
                    <div class="time-container">
                      <span class="number">#{{ props.row.id }}</span>
                      <div class="time">
                        <span>{{ props.row.createdAt | moment('HH:mm') }}</span>
                        <img
                          src="@/assets/images/icons/arrow-right-small.svg"
                          alt="arrow-right"
                        />
                        <span v-if="props.row.status === 'NEW'">Unknown</span>
                        <span v-else-if="props.row.status === 'CLOSED'">{{
                          props.row.closedAt | moment('HH:mm')
                        }}</span>
                        <span v-else>{{
                          $helper.getEnumTranslation(
                            'order_status',
                            props.row.status,
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="name">
                      {{
                        `${
                          props.formattedRow[props.column.field].streetAddress
                        } ${
                          props.formattedRow[props.column.field].streetNumber
                            ? props.formattedRow[props.column.field]
                                .streetNumber
                            : ''
                        } ${props.formattedRow[props.column.field].postalCode}`
                      }}
                    </div>
                    <div
                      class="place"
                      v-if="
                        props.formattedRow[props.column.field].flatNumber ||
                        props.formattedRow[props.column.field].floorNumber ||
                        props.formattedRow[props.column.field].gate
                      "
                    >
                      <span
                        v-if="props.formattedRow[props.column.field].gate"
                        class="entrance"
                        >Entrance
                        {{ props.formattedRow[props.column.field].gate }}</span
                      >
                      <span
                        v-if="props.formattedRow[props.column.field].flatNumber"
                        class="flat"
                        >Flat
                        {{
                          props.formattedRow[props.column.field].flatNumber
                        }}</span
                      >
                      <span
                        v-if="
                          props.formattedRow[props.column.field].floorNumber
                        "
                        class="floor"
                        >{{
                          props.formattedRow[props.column.field].floorNumber
                        }}. floor</span
                      >
                    </div>
                  </div>
                </div>
              </span>
              <span
                :class="props.column.field"
                v-else-if="props.column.field == 'user'"
              >
                <div class="contact">
                  <div class="contact-info">
                    <span class="name">
                      {{
                        props.formattedRow[props.column.field]
                          ? `${
                              props.formattedRow[props.column.field].givenName
                                ? props.formattedRow[props.column.field]
                                    .givenName
                                : ''
                            }
                          ${
                            props.formattedRow[props.column.field].familyName
                              ? props.formattedRow[props.column.field]
                                  .familyName
                              : ''
                          }`
                          : ''
                      }}
                    </span>
                    <span v-if="props.row.telephone" class="number">{{
                      props.row.telephone
                    }}</span>
                    <span
                      v-if="
                        props.formattedRow[props.column.field] &&
                        props.formattedRow[props.column.field].email
                      "
                      class="email"
                      >{{ props.formattedRow[props.column.field].email }}</span
                    >
                  </div>
                  <call-btn
                    v-if="props.row.telephone"
                    :number="props.row.telephone"
                  />
                </div>
              </span>
              <span v-else-if="props.column.field == 'courier'">
                <div class="dropdown-container">
                  <div class="main-dropdown">
                    <b-form-group>
                      <span v-if="tabIndex == 2">
                        {{ props.row.courierObj.label }}
                      </span>
                      <v-select
                        v-else
                        v-model="props.row.courierObj"
                        :options="couriers"
                        class="custom-select"
                        :clearable="true"
                        :searchable="true"
                        @input="updateCourier(props.row)"
                      >
                        <template slot="selected-option" slot-scope="option">
                          <div>
                            <img
                              v-if="
                                props.row.courier && props.row.courier.fileUrl
                              "
                              class="select-photo"
                              :src="`${props.row.courier.fileUrl}`"
                              alt=""
                            />
                            <span>{{ option.label }}</span>
                          </div>
                        </template>
                        <template #option="{ label }">
                          <img
                            v-if="
                              props.row.courier && props.row.courier.fileUrl
                            "
                            class="select-photo"
                            :src="`${props.row.courier.fileUrl}`"
                            alt=""
                          />
                          <span> {{ label }}</span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </div>
                </div>
              </span>
              <span v-else-if="props.column.field == 'id'">
                <b-button
                  @click="windowResize"
                  v-b-toggle="
                    `collapse-${props.formattedRow[props.column.field]}`
                  "
                  class="custom-btn custom-collapse"
                >
                  <img src="@/assets/images/icons/arrow-up.svg" />
                </b-button>
                <div class="collapsible-content-row">
                  <b-collapse
                    :id="`collapse-${props.formattedRow[props.column.field]}`"
                  >
                    <div class="order-payment">
                      <div class="order-payment-total">
                        {{ props.row.totalPrice | price }}
                      </div>
                      <div class="order-payment-method">
                        {{
                          $helper.getEnumTranslation(
                            'order_payment_method',
                            props.row.paymentMethod,
                          )
                        }}
                      </div>
                      <b-button
                        v-if="tabIndex !== 2"
                        :to="`/orders/${props.row.id}/edit`"
                        class="btn-edit"
                        >Edit order</b-button
                      >
                    </div>

                    <div
                      class="order-delivery-time"
                      v-if="props.row.shippingDate"
                    >
                      Delivery time:
                      {{
                        `${$helper.formatDate(
                          props.row.shippingDate,
                        )} ${$helper.formatTime(props.row.shippingDate)}`
                      }}
                    </div>

                    <div
                      v-for="(item, index) of props.row.items"
                      :key="index"
                      class="ordered-item"
                    >
                      <div class="item">
                        <div class="amount">{{ item.quantity }} pcs</div>
                        <div class="item-name">{{ item.name }}</div>
                      </div>
                      <div class="item-price">{{ item.price | price }}</div>
                    </div>

                    <div class="total-order-price">
                      <span>Total</span>
                      <span>{{ props.row.totalPrice | price }}</span>
                    </div>

                    <div v-if="props.row.description" class="order-comment">
                      <span>Comment</span>
                      <p class="comment-text">{{ props.row }}</p>
                    </div>
                  </b-collapse>
                </div>
              </span>
              <span v-else :class="props.column.field">{{
                props.formattedRow[props.column.field]
              }}</span>
            </template>

            <template slot="pagination-bottom" slot-scope="props">
                <div v-if="tabIndex != 2">
                  <table-pagination
                    v-model="currentPage"
                    :enablePerPage="true"
                    :total="pages"
                    :per-page="perPage"
                    :pageChanged="props.pageChanged"
                    :perPageChanged="props.perPageChanged"
                    @perPageChange="perPageChange"
                  />
                </div>
                <div v-else></div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import sortablejs from 'sortablejs'
import { BCollapse, BButton } from 'bootstrap-vue'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import CallBtn from '@/components/Buttons/CallBtn.vue'
import TableBullet from '@/components/Table/TableBullet.vue'
import TablePagination from '@/components/Table/TablePagination.vue'
import TitleBar from '@/components/TitleBar.vue'
import Search from '@/components/Inputs/Search.vue'
import ListUtils from '@/mixins/ListUtils'
import GoogleMap from '@/components/GoogleMap.vue'
import Toast from '@/components/Toast.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'DispatcherOrdersListing',
  components: {
    TitleBar,
    TableBullet,
    TablePagination,
    VueGoodTable,
    BCollapse,
    BButton,
    Search,
    CallBtn,
    GoogleMap,
    flatPickr,
  },
  mixins: [ListUtils],
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      perPage: 'All',
      currentPage: 1,
      pages: 0,
      couriers: [],
      search: '',
      orders: null,
      editOrder: null,
      sortable: null,
      columns: [
        {
          field: 'status',
          width: '50px',
          sortable: false,
        },
        {
          field: 'shippingAddress',
          sortable: false,
        },
        {
          field: 'user',
          sortable: false,
        },
        {
          field: 'courier',
          sortable: false,
        },
        {
          field: 'id',
          sortable: false,
          tdClass: 'collapsible',
          width: '50px',
        },
      ],
      carsMarkers: [],
      ordersMarkers: [],

      tabs: [],
      tabIndex: -1,
      finishedInitLoading: false,

      mercureToken: null,
      eventSource: null,
      orderDates: null,
    }
  },
  created() {
    window.addEventListener('resize', this.windowResize)

    // courier.onOff === true => aktivny
    const temp = [
      { id: null, title: 'All couriers' },
      { id: 'new', title: 'New Orders' },
      { id: 'old', title: 'Order History' },
    ]
    this.$Cars
      .getCollection({ params: { pagination: false } }, 'car_list')
      .then(response => {
        response.data['hydra:member'].forEach(car => {
          if (car.courier) {
            let name = `${car.courier.givenName}${car.courier.familyName}`
            if (car.courier.additionalName) {
              name = car.courier.additionalName
            }
            temp.push({
              id: car['@id'],
              courierID: car.courier['@id'],
              active: car.latitude && car.longitude && car.courier.onOff,
              name: `${car.courier.givenName} ${car.courier.familyName}`,
              title: `${name} <span>(OFF)</span>`,
            })

            this.carsMarkers.push({
              type: 'car',
              active: car.latitude && car.longitude && car.courier.onOff,
              object: car,
            })

            this.couriers.push({
              id: car.courier['@id'],
              label: name,
              numberId: car.courier.id,
            })
          }
        })
        this.tabs = temp
      })

    this.$Mercure
      .getResourceByUrl({ url: '/mercure/login' })
      .then(response => {
        if (response.status === 200) {
          this.mercureToken = response.data.token

          const url = new URL(`https://${process.env.VUE_APP_MERCURE_URL}`)
          url.searchParams.append(
            'topic',
            `https://${process.env.VUE_APP_API_URL}/cars/{id}`,
          )
          url.searchParams.append(
            'topic',
            `https://${process.env.VUE_APP_API_URL}/orders`,
          )
          url.searchParams.append(
            'topic',
            `https://${process.env.VUE_APP_API_URL}/orders/{id}`,
          )
          this.eventSource = this.$sse
            .create({
              url: url.href,
              cleanup: true,
              format: 'json',
              withCredentials: false,
              forcePolyfill: true,
              polyfillOptions: {
                headers: {
                  Authorization: `Bearer ${this.mercureToken}`,
                },
              },
            })
            .on('message', this.handleSSE)
            .on('error', () => {})
            .connect()
            .catch(err =>
              console.error('Failed make initial connection:', err),
            )
        }
      })
    const today = new Date()
    const yesterday = new Date(today.getTime())
    yesterday.setDate(yesterday.getDate() - 1)
    this.orderDates = `${this.formatDate(yesterday)}T16:00:00.000Z to ${this.formatDate(today)}T03:30:00.000Z`
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResize)
  },
  beforeDestroy() {
    this.eventSource = null
  },
  mounted() {
    this.$nextTick(() => {
      this.windowResize()
    })
  },
  methods: {
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },
    formatDate(date) {
      return [
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-')
    },
    formatDateWithTime(date) {
      return `${[
        date.getFullYear(),
        this.padTo2Digits(date.getMonth() + 1),
        this.padTo2Digits(date.getDate()),
      ].join('-')}T${[
          this.padTo2Digits(date.getHours()),
          this.padTo2Digits(date.getMinutes()),
          this.padTo2Digits(date.getSeconds()),
      ].join(':')}.000Z`
    },
    handleSSE(data) {
      if (data['@type'] === 'Car') {
        this.carsMarkers.forEach(carMarker => {
          if (carMarker.object['@id'] === data['@id']) {
            carMarker.object.latitude = data.latitude
            carMarker.object.longitude = data.longitude
            carMarker.active =
              data.latitude && data.longitude && data.courier.onOff
          }
        })

        this.tabs.forEach(tab => {
          if (tab.id === data['@id']) {
            const state = data.latitude && data.longitude && data.courier.onOff
            if (tab.active !== state) {
              this.$helper.showToast(
                this.$toast,
                Toast,
                'Courier changed status',
                `${tab.name} is now ${state ? 'active' : 'inactive'}`,
                state ? 'success' : 'warning',
              )
            }
            tab.active = state
          }
        })
      }
      if (data['@type'] === 'Order') {
        const order = JSON.parse(JSON.stringify(data))
        if (order.confirmation) {
          // only update
          const loadedOrder = this.orders.find(
            o => o['@id'] === order['@id'],
          )
          if (loadedOrder) {
            loadedOrder.confirmed = true
            if (order.confirmation.oldValue !== order.confirmation.newValue) {
              loadedOrder.confirmed = order.confirmation.newValue
            }

            if (order.status.oldValue !== order.status.newValue) {
              loadedOrder.status = order.status.newValue
            }

            if (order.courier.oldValue !== order.courier.newValue) {
              const courier = this.couriers.find(
                c => c.id === order.courier.newValue,
              )
              loadedOrder.courierObj.id = courier.id
              loadedOrder.courierObj.label = courier.label
            }
          }
        } else {
          order.courierObj = {
            label: 'Courier not selected',
            id: null,
            numberId: null,
          }

          if (this.tabIndex === 0 || this.tabIndex === 1) {
            order.highlight = true
            this.orders.unshift(order)
          }

          if (order.status === 'NEW' && order.courier === null) {
            this.ordersMarkers.push({
              type: 'order',
              object: order,
            })

            this.$helper.showToast(
              this.$toast,
              Toast,
              'New order has been created',
              `Order #${order.id} has been created`,
              'success',
            )
          }
        }
      }
    },
    windowResize() {
      if (document.querySelector('#vgt-table')) {
        const w = document.querySelector('#vgt-table').offsetWidth
        const collapsibleRows = document.querySelectorAll(
          '.collapsible-content-row',
        )
        collapsibleRows.forEach(row => {
          row.style.width = `${w}px`
          row.style.marginLeft = `-${w - 25}px`
        })
      }
    },
    perPageChange(data) {
      this.perPage = data
    },
    loadOrders() {
      if (this.$refs.goods_table_ref) {
        this.$refs.goods_table_ref.reset()
      }
      if (this.sortable) {
        this.sortable = null
      }
      this.orders = null
      this.ordersMarkers = []
      const params = {
        page: this.currentPage,
        status: ['NEW', 'PROCESSING', 'POSTPONED'],
      }

      if (this.perPage && this.perPage !== 'All') {
        params.itemsPerPage = this.perPage
      }
      if (this.perPage === 'All') {
        params.pagination = false
      }
      if (this.search !== '') {
        params.search = this.search
      }
      if (
        this.tabIndex !== 0 &&
        this.tabIndex !== -1 &&
        this.tabIndex !== 1 &&
        this.tabIndex !== 2
      ) {
        params.courier = this.tabs[this.tabIndex].courierID
        params['order[position]'] = 'asc'
        params['order[assignedAt]'] = 'asc'
      } else {
        params['order[createdAt]'] = 'asc'
      }
      if (this.tabIndex === 1) {
        params.status = ['NEW']
        params['exists[courier]'] = false
      }
      if (this.tabIndex === 2) {
        params.status = ['CANCELLED', 'CLOSED', 'UNABLE_TO_DELIVERED']
        params['order[closedAt]'] = 'desc'
        const splitDate = this.orderDates.split(' to ')
        // eslint-disable-next-line prefer-destructuring
        const dateAfter = new Date(splitDate[0])
        params['closedAt[after]'] = new Date(
            dateAfter.getTime() + Math.abs(dateAfter.getTimezoneOffset() * 60000)
        )
        // eslint-disable-next-line prefer-destructuring
        const dateBefore = new Date(splitDate[1])
        params['closedAt[before]'] = new Date(
            dateBefore.getTime() + Math.abs(dateBefore.getTimezoneOffset() * 60000)
        )
      }
      this.$Orders.getCollection({ params }, 'order_list').then(response => {
        this.orders = response.data['hydra:member']
        this.pages = response.data['hydra:totalItems']
        this.orders.forEach(order => {
          order.courierObj = {
            label: order.courier
              ? `${order.courier.givenName} ${order.courier.familyName}`
              : 'Courier not selected',
            id: order.courier ? order.courier['@id'] : null,
            numberId: order.courier ? order.courier.id : null,
          }

          this.ordersMarkers.push({
            type: 'order',
            object: order,
          })
        })
        this.finishedInitLoading = true
        this.windowResize()
        if (
          this.orders.length &&
          this.tabIndex !== 0 &&
          this.tabIndex !== -1 &&
          this.tabIndex !== 1
        ) {
          setTimeout(() => {
            this.initSortableGroups()
          }, 500)
        }
      })
    },
    courierChange() {
      if (this.finishedInitLoading) {
        this.loadOrders()
      }
    },
    updateCourier(order) {
      const body = {
        courier: order.courierObj ? order.courierObj.id : null,
      }
      this.$Orders
        .updateResource({ id: order.id, body })
        .then(response => {
          if (response.status === 200) {
            if (order.courier) {
              // order unassigned
              const title = 'Order Unassigned'
              const message = `Order #${order.id} has been assigned to another courier.`
              this.sendFirebaseNotification(
                order.courier['@id'],
                title,
                message,
              )
            }
            // order assigned
            if (order.courierObj) {
              const title = 'Order Assigned'
              const message = `Order #${order.id} has been assigned to you.`
              this.sendFirebaseNotification(
                order.courierObj.id,
                title,
                message,
              )
              this.$helper.showToast(
                this.$toast,
                Toast,
                'Courier updated',
                '',
                'success',
              )
            }
            if (this.tabs[this.tabIndex].courierID) {
              this.loadOrders()
            }
          }
        })
        .catch(error => {
          if (error.response) {
            this.$helper.showToast(
              this.$toast,
              Toast,
              error.response.data['hydra:title'],
              error.response.data['hydra:description'],
              'danger',
            )
          }
        })
    },
    sendFirebaseNotification(user, title, message) {
      if (process.env.VUE_APP_FIREBASE_SERVER_KEY === '' || process.env.VUE_APP_FIREBASE_SEND_URL === '') {
        this.$helper.showToast(
          this.$toast,
          Toast,
          'Firebase Notification Error',
          'Firebase server key and/or URL value is empty.',
          'danger',
        )
      } else {
          this.$Users.getResourceByUrl({ url: user }).then(response => {
            if (response.data.firebaseToken) {
              const userToken = response.data.firebaseToken
              const headers = {
                'Content-Type': 'application/json',
                Authorization: `key=${process.env.VUE_APP_FIREBASE_SERVER_KEY}`,
              }
              const data = {
                to: userToken,
                notification: {
                  title,
                  body: message,
                  sound: 'default',
                },
              }
              axios
                .post(`${process.env.VUE_APP_FIREBASE_SEND_URL}`, data, {
                  headers,
                })
                .then()
                .catch(error => {
                  this.$helper.showToast(
                    this.$toast,
                    Toast,
                    error.response.data.title,
                    error.response.data.message,
                    'danger',
                  )
                })
            }
          })
      }
    },
    inputChange(value) {
      this.search = value
      this.loadOrders()
    },
    onPageChange() {
      this.loadOrders()
    },
    rowStyleClass(row) {
      let styleClass = ''
      if (row.highlight) {
        styleClass = 'row-changed'
      }
      return styleClass
    },
    initSortableGroups() {
      if (this.$refs.goods_table_ref) {
        const vgtDom =
          this.$refs.goods_table_ref.$el.getElementsByClassName(
            'vgt-responsive',
          )
        const vgtTbodyDom = vgtDom[0].getElementsByTagName('tbody')
        const sortableGroups = Array.from(vgtTbodyDom)

        sortableGroups.forEach(group => {
          const dragOptions = {
            delay: 500,
            animation: 100,
            supportPointer: false,
            delayOnTouchOnly: true,
            touchStartThreshold: 5,
            onEnd: this.onDragEnd,
            handle: '.status.draggable',
          }

          const rows = group.getElementsByTagName('tr')
          Array.from(rows).forEach(row => {
            const input = row.getElementsByClassName('id_holder')
            row.setAttribute('data-id', input[0].value)
          })

          this.sortable = sortablejs.create(group, dragOptions)
        })
      }
    },
    onDragEnd() {
      if (this.sortable) {
        const ordersIDs = this.sortable.toArray()
        const body = {
          courier: this.tabs[this.tabIndex].courierID,
          orders: ordersIDs.map((o, i) => ({
            orderId: parseInt(o, 10),
            position: i,
          })),
        }
        this.$AdjustOrdering
          .createResource({ body })
          .then(response => {
            if (response.status === 201) {
              const title = 'Order change'
              const message = `Order of orders has been changed`
              this.sendFirebaseNotification(
                this.tabs[this.tabIndex].courierID,
                title,
                message,
              )
              this.$helper.showToast(
                this.$toast,
                Toast,
                'Order of orders for courier changed',
                '',
                'success',
              )
            }
          })
          .catch(error => {
            if (error.response) {
              this.$helper.showToast(
                this.$toast,
                Toast,
                error.response.data['hydra:title'],
                error.response.data['hydra:description'],
                'danger',
              )
            }
          })
      }
    },
  },
}
</script>
