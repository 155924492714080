<template>
  <gmap-map
    v-if="markers.length"
    ref="gmap"
    :center="center"
    :zoom="13"
    :options="mapOptions"
    style="width: 100%; height: 100%; overflow: hidden"
  >
    <gmap-custom-marker
      v-for="(m, index) in markers"
      :key="index"
      :marker="{ lat: m.position.lat, lng: m.position.lng }"
    >
      <div
        v-if="m.type === 'car'"
        class="google-map-offer-marker car"
        :style="`background: ${m.object.color}`"
      >
        <img src="@/assets/images/icons/truck.svg" alt="" />
        <div class="google-map-offer-marker-detail">
          <h4>
            {{ m.object.name }} <br />
            Courier:
            {{
              m.object.courier
                ? `${m.object.courier.givenName} ${m.object.courier.familyName}`
                : ''
            }}
          </h4>
        </div>
      </div>
      <div
        v-else-if="m.type === 'order'"
        class="google-map-offer-marker"
        :style="`background: ${m.color}`"
      >
        <div class="google-map-offer-marker-detail">
          <h4>
            Order number: #{{ m.object.id }} <br />
            Status:
            {{ $helper.getEnumTranslation('order_status', m.object.status) }}
            <br />
            Courier:
            {{
              m.object.courier
                ? `${m.object.courier.givenName} ${m.object.courier.familyName}`
                : ''
            }}
          </h4>
        </div>
      </div>
    </gmap-custom-marker>
  </gmap-map>
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import GmapCustomMarker from 'vue2-gmap-custom-marker'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
})

export default {
  components: {
    'gmap-custom-marker': GmapCustomMarker,
  },
  props: {
    cars: { type: Array, default: () => [] },
    orders: { type: Array, default: () => [] },
  },
  data() {
    return {
      markers: [],
      center: null,
      mapOptions: {
        styles: [
          {
            featureType: 'all',
            elementType: 'labels.text.fill',
            stylers: [
              {
                saturation: 36,
              },
              {
                color: '#000000',
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                visibility: 'on',
              },
              {
                color: '#000000',
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: 'all',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
          {
            featureType: 'landscape',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000',
              },
              {
                lightness: 8,
              },
            ],
          },
        ],
      },
    }
  },
  watch: {
    $props: {
      handler() {
        this.createMarkers()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async createMarkers() {
      this.markers = []
      this.cars.forEach(async car => {
        const lat = car.object.latitude ? parseFloat(car.object.latitude) : null
        const lng = car.object.longitude ? parseFloat(car.object.longitude) : null
        if (car.active && lat && lng) {
          if (!this.markers.length) {
            this.center = {}
            this.center.lat = lat
            this.center.lng = lng
          }
          this.markers.push({
            type: 'car',
            object: car.object,
            position: {
              lat,
              lng,
            },
          })
        }
      })

      // musi byt for kvoli limitu requestov na google maps api
      // eslint-disable-next-line no-restricted-syntax
      for (const order of this.orders) {
        const lat = order.object.shippingAddress.latitude ? parseFloat(order.object.shippingAddress.latitude) : null
        const lng = order.object.shippingAddress.longitude ? parseFloat(order.object.shippingAddress.longitude) : null
        if (lat && lng) {
          if (!this.markers.length) {
            this.center = {}
            this.center.lat = lat
            this.center.lng = lng
          }
          this.markers.push({
            type: 'order',
            color: this.getOrderColor(order.object),
            object: order.object,
            position: {
              lat,
              lng
            },
          })
        }
      }
    },
    justTimeout() {
      return new Promise(resolve => {
        setTimeout(resolve, 50)
      })
    },
    getOrderColor(order) {
      let color = '#fdb913'
      this.cars.forEach(car => {
        if (
          car.object.courier &&
          order.courier &&
          car.object.courier['@id'] === order.courier['@id']
        ) {
          color = car.object.color
        }
      })
      return color
    },
  },
}
</script>

<style lang="scss">
.google-map-offer-marker {
  position: relative;
  background: var(--primary);
  width: 25px;
  height: 25px;
  border-radius: 25px;
  &.car {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .google-map-offer-marker-detail {
      bottom: 50px;
    }
  }
  &:hover {
    .google-map-offer-marker-detail {
      visibility: visible;
      opacity: 1;
    }
  }
  &-detail {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    bottom: 30px;
    left: 50%;
    width: 200px;
    background: #2d2a2a;
    padding: 10px 15px;
    transform: translate(-50%, 0);
    border-radius: 6px;
    z-index: 99999 !important;
    h4 {
      color: #fff;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      font-weight: normal;
      a {
        color: inherit;
      }
    }
  }
}
</style>
